export default {
  data() {
    return {
      id: "",
      idWarehouse: "",
      texts: "",
      iCurrentPage: 1,
      iNumPages: 0,
      screenWidth: 0,
      bLoading: false,
      isMobileAndTablet: false,
      wareHouseDetailArr: "",
      wareHouseFileDetailArr: [],
      apiGlobalWarehouseDetail: "",
      apiGlobalDocumentDetail: "",
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  beforeMount() {
    this.texts = FILE.vendorTexts[this.selectLanguage];
  },
  methods: {
    warehousesDetail() {
      DB.get(this.apiGlobalWarehouseDetail, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.wareHouseDetailArr = response.data.results;
          this.warehouseFileDetail();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    warehouseFileDetail() {
      DB.get(this.apiGlobalDocumentDetail, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.wareHouseFileDetailArr = response.data.results;
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },

    deleteItem(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.vendorDetails.warehousesDetail.delete.title,
        descipcion: this.texts.vendorDetails.warehousesDetail.delete
          .description,
        note: this.texts.vendorDetails.warehousesDetail.delete.note,
        chkLabel: this.texts.vendorDetails.warehousesDetail.delete.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${item.sVendorEnterpriseId}/warehouses/${item.sVendorWarehouseId}`,
        dobleConfirm: true,
      });
    },
    downloadFile(fileUrl) {
      window.open(fileUrl, "_blank");
    },
    close() {
      this.$store.commit("setdialogGlobalWarehouse", {
        active: false,
        arr: [],
      });
    },
    emitClose() {
      this.$store.commit("setdialogGlobalWarehouse", {
        active: false,
        arr: [],
      });
    },
    handleResize() {
      if (window.innerWidth >= 960) {
        this.isMobileAndTablet = true;
        this.screenWidth = 80;
      } else {
        this.isMobileAndTablet = false;
        this.screenWidth = 100;
      }
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogGlobalDetailWarehouse() {
      return this.$store.state.dialogGlobalDetailWarehouse;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.vendorTexts[this.selectLanguage];
      }
    },
    dialogGlobalDetailWarehouse: function() {
      if (this.dialogGlobalDetailWarehouse) {
        this.texts = FILE.vendorTexts[this.selectLanguage];
        this.apiGlobalWarehouseDetail = this.$store.state.apiGlobalWarehouseDetail;
        this.apiGlobalDocumentDetail = this.$store.state.apiGlobalDocumentDetail;

        this.warehousesDetail();
      }
    },
  },
};
